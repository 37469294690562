<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Synchronisation de l'agenda</v-card-title>
        <v-card-subtitle>Sélectionner les agendas à synchroniser</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item v-for="(item, index) in sync_calendar" :key="index">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-switch v-model="item.enable" class="float-right" switch size="lg"/>
                    <v-btn icon class="ml-2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon class="ml-2" color="red">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Agenda_sync',
    data() {
        return {
            sync_calendar : JSON.parse(localStorage.getItem('settings')).preferencies.sync_calendar,
            sync_calendar_backup : JSON.parse(localStorage.getItem('settings')).preferencies.sync_calendar,
        }
    },
    methods: {
        saveSettings() {
            if (JSON.stringify(this.sync_calendar_backup) !== JSON.stringify(this.sync_calendar)) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.preferencies.sync_calendar = this.sync_calendar;
                this.sync_calendar_backup = JSON.parse(JSON.stringify(this.sync_calendar));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.sync_calendar = JSON.parse(localStorage.getItem('settings')).preferencies.sync_calendar;
        },
        isChanges () {
            if (JSON.stringify(this.sync_calendar_backup) !== JSON.stringify(this.sync_calendar)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>
<style lang="">
    
</style>